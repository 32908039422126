import { Box, Conditional, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { useMemo } from 'react';
import { RadioButton } from '../../../../../../../../../../../../components/common/radio-button';
import { startPrintPrintJobModalAtoms } from '../../../../../start-print-job-modal.atoms';
import { PaginatedInventory } from '../../../../../../../../../../../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import {
  spoolInventoryNeedValues,
  spoolInventoryDetails,
} from './new-spool-inventory.constants';
import { getInventoryRequestsForInventory } from './helpers/get-inventory-requests-for-inventory';
import { NewSpoolInventoryPropsDef } from './new-spool-inventory.types';

export const NewSpoolInventory = (props: NewSpoolInventoryPropsDef) => {
  const { isLoading, isOpaqueMaterial, materialInventories } = props;

  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );

  const inventoryItems = useMemo(() => {
    if (materialInventories) {
      // ? indexed in display order
      const inventoryIdsToShow = isOpaqueMaterial
        ? [
            spoolInventoryDetails.opaqueSandable.id,
            spoolInventoryDetails.opaqueOldFormulation.id,
          ]
        : [spoolInventoryDetails.translucent.id];

      return inventoryIdsToShow.map((idToShow) => {
        const matchingInventory = materialInventories.find(({ id }) => {
          return id === idToShow;
        });

        const { description } = Object.values(spoolInventoryDetails).find(
          ({ id }) => {
            return id === idToShow;
          },
        );

        const updatedInventory: PaginatedInventory = {
          ...matchingInventory,
          description,
        };

        return updatedInventory;
      });
    }

    return [];
  }, [materialInventories]);

  return (
    <Stack gap=".5x">
      <Typography text="New spool inventory" variant="h6" />

      <Stack gap="x">
        <Conditional
          key="is-loading-inventories"
          condition={isLoading}
          Fallback={
            <Conditional
              condition={isOpaqueMaterial}
              Fallback={
                <>
                  {inventoryItems.map(({ id, name }) => {
                    return (
                      <Typography
                        key={id}
                        text={`A transaction of ${spoolInventoryNeedValues.translucent}kg of Inventory #${id}: ${name} will be recorded.`}
                      />
                    );
                  })}
                </>
              }
            >
              <Typography
                text={`A transaction of ${spoolInventoryNeedValues.opaque}kg of inventory will be recorded. Select material:`}
              />

              {inventoryItems.map((inventory, index) => {
                const { description, id, name } = inventory;

                const groupValue = String(id);

                // ? The inventory request only ever has one index
                const selectedInventoryId = inventoryRequests[0]?.inventoryId;

                const value = String(selectedInventoryId || '');

                const isChecked = inventoryRequests.length
                  ? id === selectedInventoryId
                  : index === 0;

                return (
                  <RadioButton
                    key={groupValue}
                    buttonVariant="radio"
                    checked={isChecked}
                    groupValue={groupValue}
                    name={groupValue}
                    value={value}
                    onSelected={() => {
                      const inventoryRequests =
                        getInventoryRequestsForInventory(inventory);

                      setInventoryRequests(inventoryRequests);
                    }}
                  >
                    <Box>
                      <Typography display="inline" text={description} />
                      &nbsp;
                      <Typography
                        color="t2"
                        display="inline"
                        text={`- #${id}: ${name}`}
                      />
                    </Box>
                  </RadioButton>
                );
              })}
            </Conditional>
          }
        >
          <Icon color="link" name="animated:loader" size="2.4rem" />
        </Conditional>
      </Stack>
    </Stack>
  );
};
