import { useEffect, useState } from 'react';
import { clone, debounce } from 'lodash';
import {
  Button,
  Cell,
  Flex,
  Grid,
  Stack,
  TextArea,
  TextField,
  Typography,
  productColorsMap,
  Box,
  TransformFileName,
  HandleUploadsComplete,
} from 'gantri-components';
import { useHandleFetchDesign } from '../../hooks/fetch-design';
import { DesignHeading } from '../design-heading';
import {
  finalizationRecords,
  initialEngineering,
  optimizationRecords,
  qualityRecords,
} from './design-engineering.constants';
import {
  getExtension,
  getNameWithoutExtension,
} from '../../../../helpers/firebase';
import Modal from '../../../../components/modals';
import { Messages } from '../messages';
import SelectInput from '../../../../components/common/inputs/select-input';
import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';
import { designsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import {
  StyledActions,
  StyledFileUploader,
  StyledReviewItem,
} from './design-engineering.styles';
import { useSpinner } from '../../../../hooks';
import {
  useInvalidateFetchDesignCache,
  useUpdateDesign,
} from '../../../../api/designs/routes';
import { useFirebaseFileUploader } from '../../../../hooks/use-firebase-file-uploader';
import {
  EngineeringPrototypingImage,
  FinishedPrototype,
} from '../../../../api/designs/designs.types';

export const DesignEngineering = () => {
  const { onInterceptRequest } = useSpinner();
  const { current, setCurrent } = useHandleFetchDesign();
  const [engineering, setEngineering] =
    useState<Design['engineering']>(initialEngineering);
  const [canProceed, setCanProceed] = useState(false);
  const [photoName, setPhotoName] = useState('');
  const [color, setColor] = useState('');
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [reason, setReason] = useState('');

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();

  const { notifyAxiosError } = useNotification();

  const thirdStep = current?.model?.subSteps?.['3'];
  const thirdStepColors = thirdStep?.colors?.map(({ code }) => {
    return productColorsMap[code];
  });

  const { onUpdateDesign } = useUpdateDesign({
    onSuccess: invalidateFetchDesignCache,
    showLoading: true,
  });

  const updateDesign = async () => {
    await onUpdateDesign({
      engineering,
      id: current.id,
      step: 'Engineering',
    });
  };

  const recordUpdated = (groupKey, key, value) => {
    return async () => {
      const temporal = clone(engineering);

      engineering[groupKey][key] = value;
      setEngineering(temporal);

      await designsApi.saveDesign(current.id, {
        engineering: temporal,
        save: true,
        step: 'Engineering',
      });

      await invalidateFetchDesignCache();
    };
  };

  const prototypeUploadPath = `designs/${current?.id}/prototyping`;

  const getOnPrototypeDelete = (index: number) => {
    return async () => {
      const images = [...engineering.prototyping.images];

      images.splice(index, 1);

      await recordUpdated('prototyping', 'images', images)();
    };
  };

  const onPrototypeUploaded: HandleUploadsComplete = async ([
    { fileName, fileSize, fileUrl },
  ]) => {
    const images = [...engineering.prototyping.images];

    const name = photoName || getNameWithoutExtension(fileName);

    images.push({
      fileExtension: getExtension(fileName),
      fileName: name,
      fileSize,
      fileUrl,
      name,
      nameOfPhoto: name,
    });

    await recordUpdated('prototyping', 'images', images)();
    setPhotoName('');
  };

  const { fileUploaderProps: prototypeFileUploaderProps } =
    useFirebaseFileUploader({
      fileUrl: null,
      handleUploadsComplete: onPrototypeUploaded,
      onFileDelete: null,
      path: prototypeUploadPath,
    });

  const onFinishedPrototypeDelete = (index: number) => {
    return async () => {
      const prototypes = [...engineering.finalization.finishedPrototypes];

      prototypes.splice(index, 1);

      await recordUpdated('finalization', 'finishedPrototypes', prototypes)();
    };
  };

  const onFinishedPrototypeUploaded: HandleUploadsComplete = async ([
    { fileName, fileSize, fileUrl },
  ]) => {
    const prototypes = [...engineering.finalization.finishedPrototypes];

    const name = getNameWithoutExtension(fileName);

    prototypes.push({
      color,
      fileExtension: getExtension(fileName),
      fileName: name,
      fileSize: String(fileSize),
      fileUrl,
      name,
    });

    await recordUpdated('finalization', 'finishedPrototypes', prototypes)();
    setColor('');
  };

  const { fileUploaderProps: finishedPrototypeFileUploaderProps } =
    useFirebaseFileUploader({
      fileUrl: null,
      handleUploadsComplete: onFinishedPrototypeUploaded,
      onFileDelete: null,
      path: prototypeUploadPath,
    });

  const onPrototypeNameTextChange = (index) => {
    return async (value: string) => {
      const images = [...engineering.prototyping.images];

      if (images[index].nameOfPhoto !== value) {
        images[index].nameOfPhoto = value;

        await recordUpdated('prototyping', 'images', images)();
      }
    };
  };

  const onColorUpdated = (index: number) => {
    return async (value: string) => {
      const prototypes = [...engineering.finalization.finishedPrototypes];

      if (prototypes[index].fileName !== value) {
        prototypes[index].color = value;

        await recordUpdated('prototyping', 'finishedPrototypes', prototypes)();
      }
    };
  };

  const onNewImageNameTextChange = (value: string) => {
    setPhotoName(value);
  };

  const toggleModalConfirm = () => {
    setConfirmVisible(!confirmVisible);
    setReason('');
  };

  const handleModalConfirm = async () => {
    await onInterceptRequest(async () => {
      try {
        await designsApi.failDesign(current.id, {
          failReason: reason,
          step: 'Engineering',
        });

        setConfirmVisible(false);
        await invalidateFetchDesignCache();
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to fail design.' });
      }
    });
  };

  const transformFileName: TransformFileName = ({
    fileName,
    removeWhitespace,
  }) => {
    return removeWhitespace(fileName);
  };

  const thumbnailSize = '40rem';

  interface PrototypingItemProps {
    index: number;
    item: EngineeringPrototypingImage;
  }

  const PrototypingItem = (props: PrototypingItemProps) => {
    const { index, item } = props;

    const { fileUploaderProps } = useFirebaseFileUploader({
      fileUrl: item.fileUrl,
      handleUploadsComplete: onPrototypeUploaded,
      onFileDelete: getOnPrototypeDelete(index),
      path: prototypeUploadPath,
    });

    return (
      <Cell>
        <Box marginBottom="2x">
          <StyledFileUploader
            {...fileUploaderProps}
            fileName={item.fileName}
            thumbnailSize={thumbnailSize}
            transformFileName={transformFileName}
            variant="thumbnail"
          />
        </Box>
        <TextField
          placeholder="Name of photo"
          value={item.nameOfPhoto}
          onTextChange={debounce(onPrototypeNameTextChange(index), 300)}
        />
      </Cell>
    );
  };

  interface FinishedPrototypeItemProps {
    index: number;
    item: FinishedPrototype;
  }

  const FinishedPrototypeItem = (props: FinishedPrototypeItemProps) => {
    const { index, item } = props;

    const { fileUploaderProps } = useFirebaseFileUploader({
      fileUrl: item.fileUrl,
      handleUploadsComplete: onFinishedPrototypeUploaded,
      onFileDelete: onFinishedPrototypeDelete(index),
      path: prototypeUploadPath,
    });

    return (
      <Cell>
        <Box marginBottom="2x">
          <StyledFileUploader
            {...fileUploaderProps}
            fileName={item.fileName}
            thumbnailSize={thumbnailSize}
            transformFileName={transformFileName}
            variant="thumbnail"
          />
        </Box>
        <SelectInput
          labelProperty="shortColorName"
          options={thirdStepColors}
          placeholder="Color"
          value={item.color}
          valueProperty="code"
          onChange={onColorUpdated(index)}
        />
      </Cell>
    );
  };

  useEffect(() => {
    if (current?.engineering) {
      setEngineering(current.engineering);
      setCanProceed(current.engineering.stepProgress === 100);
    }
  }, [current?.engineering]);

  return current?.model && current?.engineering ? (
    <Grid columns="8fr 4fr" gap="3rem" paddingTop="s1">
      <Grid columns={1} gap="12rem">
        <DesignHeading design={current} />

        <Cell>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography
              text="Modeling optimizations"
              textStyle="bold"
              variant="h2"
            />
            <Typography text="Complete" variant="p2" />
          </Flex>

          <Stack gap="0" marginTop="3rem">
            {optimizationRecords.map((record) => {
              return (
                <StyledReviewItem key={record.key}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Typography text={record.label} variant="p2" />

                    <Grid columns={2} gap="1rem">
                      <Button
                        text="Yes"
                        variant={
                          engineering.modelingOptimization[record.key]
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={recordUpdated(
                          'modelingOptimization',
                          record.key,
                          true,
                        )}
                      />
                      <Button
                        text="No"
                        variant={
                          engineering.modelingOptimization[record.key]
                            ? 'secondary'
                            : 'primaryAlert'
                        }
                        onClick={recordUpdated(
                          'modelingOptimization',
                          record.key,
                          false,
                        )}
                      />
                    </Grid>
                  </Flex>
                </StyledReviewItem>
              );
            })}
          </Stack>

          <Modal type="confirm" visible={confirmVisible}>
            <Cell paddingTop="6rem">
              <Grid columns={1} gap="4rem">
                <Grid columns={1} gap="1rem">
                  <Typography
                    align="center"
                    text="Fail Engineering"
                    textStyle="bold"
                    variant="p1"
                  />

                  <Typography
                    align="center"
                    color="alert"
                    text="Warning: This will fail the entire design and cannot be undone."
                  />
                </Grid>

                <TextArea
                  minRows={5}
                  placeholder="Enter reason"
                  value={reason}
                  onTextChange={debounce(setReason, 300)}
                />

                <Grid columns={2} gap="1rem">
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={toggleModalConfirm}
                  />
                  <Button
                    size="large"
                    text="Confirm"
                    variant="primaryAlert"
                    onClick={handleModalConfirm}
                  />
                </Grid>
              </Grid>
            </Cell>
          </Modal>
        </Cell>

        <Grid columns={1} gap="1rem">
          <Typography text="Prototyping" textStyle="bold" variant="h2" />
          <Typography
            color="t2"
            marginTop="1rem"
            text="Upload photos of prototypes after they finish printing"
          />

          <Grid columns="repeat(2, 1fr)" gap="3rem">
            {engineering.prototyping.images.map((item, index) => {
              return (
                <PrototypingItem key={item.fileUrl} index={index} item={item} />
              );
            })}
            <Cell>
              <Box marginBottom="2x">
                <StyledFileUploader
                  key={engineering.prototyping.images.length}
                  {...prototypeFileUploaderProps}
                  fileName={null}
                  isUploaderOnly
                  thumbnailSize={thumbnailSize}
                  transformFileName={transformFileName}
                  variant="thumbnail"
                />
              </Box>
              <TextField
                placeholder="Name of photo"
                value={photoName}
                onTextChange={debounce(onNewImageNameTextChange, 300)}
              />
            </Cell>
          </Grid>
        </Grid>

        <Cell>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography text="Quality testing" textStyle="bold" variant="h2" />
            <Typography text="Complete" variant="p2" />
          </Flex>

          <Stack gap="0" marginTop="3rem">
            {qualityRecords.map((record) => {
              return (
                <StyledReviewItem key={record.key}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Typography text={record.label} variant="p2" />

                    <Grid columns={2} gap="1rem">
                      <Button
                        text="Yes"
                        variant={
                          engineering.qualityTesting[record.key]
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={recordUpdated(
                          'qualityTesting',
                          record.key,
                          true,
                        )}
                      />
                      <Button
                        text="No"
                        variant={
                          engineering.qualityTesting[record.key]
                            ? 'secondary'
                            : 'primaryAlert'
                        }
                        onClick={recordUpdated(
                          'qualityTesting',
                          record.key,
                          false,
                        )}
                      />
                    </Grid>
                  </Flex>
                </StyledReviewItem>
              );
            })}
          </Stack>
        </Cell>

        <Cell>
          <Flex alignItems="center" justifyContent="space-between">
            <Typography text="Finalization" textStyle="bold" variant="h2" />
            <Typography text="Complete" variant="p2" />
          </Flex>

          <Stack gap="0" marginTop="3rem">
            {finalizationRecords.map((record) => {
              return (
                <StyledReviewItem key={record.key}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Typography text={record.label} variant="p2" />

                    <Grid columns={2} gap="1rem">
                      <Button
                        text="Yes"
                        variant={
                          engineering.finalization[record.key]
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={recordUpdated(
                          'finalization',
                          record.key,
                          true,
                        )}
                      />
                      <Button
                        text="No"
                        variant={
                          engineering.finalization[record.key]
                            ? 'secondary'
                            : 'primaryAlert'
                        }
                        onClick={recordUpdated(
                          'finalization',
                          record.key,
                          false,
                        )}
                      />
                    </Grid>
                  </Flex>
                </StyledReviewItem>
              );
            })}
          </Stack>
        </Cell>

        <Grid columns={1} gap="1rem">
          <Typography
            text="Finished prototypes"
            textStyle="bold"
            variant="h2"
          />
          <Typography
            color="t2"
            marginTop="1rem"
            text="Upload photos of prototypes after they finish printing"
          />

          <Grid columns="repeat(2, 1fr)" gap="3rem">
            {engineering.finalization.finishedPrototypes.map((item, index) => {
              return (
                <FinishedPrototypeItem
                  key={item.fileUrl}
                  index={index}
                  item={item}
                />
              );
            })}
            <Cell>
              <Box marginBottom="2x">
                <StyledFileUploader
                  key={engineering.finalization.finishedPrototypes.length}
                  {...finishedPrototypeFileUploaderProps}
                  fileName={null}
                  isUploaderOnly
                  thumbnailSize={thumbnailSize}
                  transformFileName={transformFileName}
                  variant="thumbnail"
                />
              </Box>
              <SelectInput
                labelProperty="shortColorName"
                options={thirdStepColors}
                placeholder="Color"
                value={color}
                valueProperty="code"
                onChange={setColor}
              />
            </Cell>
          </Grid>
        </Grid>

        {current.engineering.status !== 'Completed' &&
          current.engineering.status !== 'Failed' && (
            <StyledActions>
              <Grid
                columns="repeat(auto-fit, minmax(0, 12rem))"
                gap="1rem"
                justifyContent="center"
              >
                <Button
                  text="Fail"
                  variant="primaryAlert"
                  onClick={toggleModalConfirm}
                />
                <Button
                  disabled={!canProceed}
                  text="Proceed"
                  onClick={updateDesign}
                />
              </Grid>
            </StyledActions>
          )}
        <Cell />
      </Grid>
      <Cell>
        <Messages
          current={current}
          designId={current.id}
          messages={current.messages || []}
          notes={current.notes || []}
          updateMessages={setCurrent}
        />
      </Cell>
    </Grid>
  ) : null;
};
