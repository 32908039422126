import { v4 as uuidv4 } from 'uuid';
import { InventoryRequest } from '../../../../../../../../../../../../../../api/jobs/jobs.types';
import { PaginatedInventory } from '../../../../../../../../../../../../../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import {
  spoolInventoryNeedValues,
  spoolInventoryDetails,
} from '../../new-spool-inventory.constants';

export const getInventoryRequestsForInventory = (
  inventory: PaginatedInventory,
) => {
  if (inventory) {
    const { id, name, status, stock, type, unit } = inventory;

    const inventoryRequests: InventoryRequest[] = [
      {
        discarded: 0,
        id: uuidv4(),
        inventory: {
          id,
          name,
          status,
          stock: Number(stock || null),
          type,
          unit,
        },
        inventoryId: id,
        need:
          id === spoolInventoryDetails.translucent.id
            ? spoolInventoryNeedValues.translucent
            : spoolInventoryNeedValues.opaque,
      },
    ];

    return inventoryRequests;
  }

  return [];
};
