import { useRecoilValue } from 'recoil';
import { HandleUploadsComplete } from 'gantri-components';
import {
  ProductLevelAssets,
  SkuLevelAssets,
} from '../../../../../../api/products/products.types';
import { useNotification } from '../../../../../../hooks/useNotification';
import { productsApi } from '../../../../../../api';
import { productAtoms } from '../../../../product.atoms';
import { useInvalidateGetAllProductsCache } from '../../../../../../api/products/routes';
import { useUpdateProductAssets } from '../../../../../../api/products/routes/update-product-assets';
import { FirebaseUploadedFileDetails } from '../../../../../../hooks/use-firebase-file-uploader/use-firebase-file-uploader.types';
import { productSkuLevelAssetsFileTypes } from '../../components/sku-level-assets/components/bulk-upload-modal/bulk-upload-modal.constants';
import { useInvalidateFetchSkuAssetsCache } from '../../../../../../api/products/routes/fetch-sku-assets';
import { useFetchProductData } from '../../../../hooks/use-fetch-product-data';

export type ProductAssetsArrayFileType =
  | Extract<keyof SkuLevelAssets, 'whiteBackgroundPhotos'>
  | Extract<keyof ProductLevelAssets, 'videos'>;

export const useHandleAssets = () => {
  const { invalidateFetchProductCache } = useFetchProductData();

  const { invalidateGetAllProductsCache } = useInvalidateGetAllProductsCache();

  const { invalidateFetchBulkProductAssetsCache } =
    useInvalidateFetchSkuAssetsCache();

  const product = useRecoilValue(productAtoms.product);

  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const { notifyAxiosError } = useNotification();

  const { onUpdateProductAssets } = useUpdateProductAssets({
    onError: async () => {
      await invalidateFetchProductCache();
    },
    onSuccess: async () => {
      await invalidateFetchProductCache();
      await invalidateGetAllProductsCache();
      await invalidateFetchBulkProductAssetsCache();
    },
    showLoading: true,
  });

  const getOnReorderPhotos = ({
    fileType,
  }: {
    fileType: ProductAssetsArrayFileType;
  }) => {
    return async (updatedFileNames: string[]) => {
      if (fileType === productSkuLevelAssetsFileTypes.whiteBackgroundPhotos) {
        await onUpdateProductAssets({
          [fileType]: updatedFileNames,
          productId: product.id,
          sku: selectedSku,
        });
      } else {
        await onUpdateProductAssets({
          [fileType]: updatedFileNames,
          productId: product.id,
        });
      }
    };
  };

  const getOnProductAssetUpload = ({
    assets,
    fileType,
  }: {
    assets: string[];
    fileType: ProductAssetsArrayFileType;
  }) => {
    return async (uploadedFileNames: string[]) => {
      const updatedAssets = [...assets, ...uploadedFileNames];

      if (fileType === productSkuLevelAssetsFileTypes.whiteBackgroundPhotos) {
        await onUpdateProductAssets({
          [fileType]: updatedAssets,
          productId: product.id,
          sku: selectedSku,
        });
      } else {
        await onUpdateProductAssets({
          [fileType]: updatedAssets,
          productId: product.id,
        });
      }
    };
  };

  const getOnProductAssetReplace = ({
    assets,
    fileType,
  }: {
    assets: string[];
    fileType: ProductAssetsArrayFileType;
  }) => {
    return async (updatedFileName: string, replaceIndex: number) => {
      const updatedAssets = assets.map((fileName, index) => {
        return index === replaceIndex ? updatedFileName : fileName;
      });

      if (fileType === productSkuLevelAssetsFileTypes.whiteBackgroundPhotos) {
        await onUpdateProductAssets({
          [fileType]: updatedAssets,
          productId: product.id,
          sku: selectedSku,
        });
      } else {
        await onUpdateProductAssets({
          [fileType]: updatedAssets,
          productId: product.id,
        });
      }
    };
  };

  const getOnProductAssetRemove = ({
    assets,
    fileType,
  }: {
    assets: string[];
    fileType: ProductAssetsArrayFileType;
  }) => {
    return async (removedIndex: number) => {
      const updatedAssets = assets.filter((_fileName, index) => {
        return index !== removedIndex;
      });

      if (fileType === productSkuLevelAssetsFileTypes.whiteBackgroundPhotos) {
        await onUpdateProductAssets({
          [fileType]: updatedAssets,
          productId: product.id,
          sku: selectedSku,
        });
      } else {
        await onUpdateProductAssets({
          [fileType]: updatedAssets,
          productId: product.id,
        });
      }
    };
  };

  const handlePhotoSelected = async (fileName: string) => {
    try {
      await productsApi.markPhotoAsSelected(product.id, {
        photo: fileName,
        sku: selectedSku,
      });

      await invalidateFetchProductCache();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to mark photo as selected.',
      });
    }
  };

  const onDeleteUsdz = async () => {
    await onUpdateProductAssets({
      productId: product.id,
      sku: selectedSku,
      usdz: null,
    });
  };

  const onUsdzUploadComplete: HandleUploadsComplete<
    FirebaseUploadedFileDetails
  > = async ([{ fileName, fileUrl }]) => {
    await onUpdateProductAssets({
      productId: product.id,
      sku: selectedSku,
      usdz: { fileName, fileUrl },
    });
  };

  return {
    getOnFileRecovered: getOnProductAssetReplace,
    getOnProductAssetRemove,
    getOnProductAssetReplace,
    getOnProductAssetUpload,
    getOnReorderPhotos,
    handlePhotoSelected,
    onDeleteUsdz,
    onUsdzUploadComplete,
  };
};
