import { SpoolChanged } from '../../../start-print-job-modal.types';
import { spoolChangedTypes } from '../../../start-print-job-modal.constants';

export const spoolChangeOptions: {
  groupValue: SpoolChanged;
  labelHtmlText: string;
}[] = [
  {
    groupValue: spoolChangedTypes.noChange,
    labelHtmlText: 'No spool change',
  },
  {
    groupValue: spoolChangedTypes.yesUsed,
    labelHtmlText: 'Yes, <strong>used</strong> spool added',
  },
  {
    groupValue: spoolChangedTypes.yesNew,
    labelHtmlText: 'Yes, <strong>new</strong> spool added',
  },
];
