import { Checkbox, Conditional, Stack, Typography } from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFetchPaginatedInventories } from '../../../../../../../../../../api/inventories/routes/fetch-paginated-inventories';
import { JobDetails } from '../../../../../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { RadioButton } from '../../../../../../../../../../components/common/radio-button';
import { inventoryTypes } from '../../../../../../../../../../constants/options';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { startPrintPrintJobModalAtoms } from '../../../start-print-job-modal.atoms';
import { spoolChangedTypes } from '../../../start-print-job-modal.constants';
import { NewSpoolInventory } from './components/new-spool-inventory';
import { getInventoryRequestsForInventory } from './components/new-spool-inventory/helpers/get-inventory-requests-for-inventory';
import { spoolInventoryDetails } from './components/new-spool-inventory/new-spool-inventory.constants';
import { spoolChangeOptions } from './machine-tasks-content.constants';

export const MachineTasksContent = (props: { job: JobDetails }) => {
  const { job } = props;

  const { material } = job.part;

  const isOpaqueMaterial = material === 'Opaque GPP';

  const buildPlateNeedsChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.buildPlateNeedsChanged,
  );
  const [buildPlateChanged, setBuildPlateChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.buildPlateChanged,
  );
  const [spoolChanged, setSpoolChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.spoolChanged,
  );
  const setInventoryRequests = useSetRecoilState(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );

  const isNewSpool = spoolChanged === spoolChangedTypes.yesNew;

  const { data, isLoading } = useFetchPaginatedInventories({
    fetchArgs: {
      active: true,
      search: 'Material',
      types: [inventoryTypes.material],
    },
  });

  const materialInventories = data?.inventories;

  useEffect(() => {
    if (isNewSpool && materialInventories) {
      if (isOpaqueMaterial) {
        const opaqueSandableInventory = materialInventories.find(({ id }) => {
          return id === spoolInventoryDetails.opaqueSandable.id;
        });
        const inventoryRequests = getInventoryRequestsForInventory(
          opaqueSandableInventory,
        );

        setInventoryRequests(inventoryRequests);
      } else {
        const translucentInventory = materialInventories.find(({ id }) => {
          return id === spoolInventoryDetails.translucent.id;
        });
        const inventoryRequests =
          getInventoryRequestsForInventory(translucentInventory);

        setInventoryRequests(inventoryRequests);
      }
    } else {
      setInventoryRequests([]);
    }
  }, [isNewSpool, materialInventories]);

  return (
    <Stack gap="4x">
      <ModalContentHeading titleText="Machine tasks" />
      <Stack gap="5x">
        <Conditional condition={buildPlateNeedsChanged}>
          <Stack gap="x">
            <Typography text="Change build plate" variant="h5" />
            <Typography text="This machine has completed 20 prints. Please change build plate to ensure print adhesion." />
            <Checkbox
              aria-label="Build plate has been changed."
              checked={buildPlateChanged}
              labelText="Build plate has been changed."
              onSelected={setBuildPlateChanged}
            />
          </Stack>
        </Conditional>

        <Stack gap="x">
          <Typography text="Spool Change" variant="h5" />
          <Typography text="Did you perform a spool change to start print on this machine?" />
          {spoolChangeOptions.map(({ groupValue, labelHtmlText }) => {
            return (
              <RadioButton
                key={groupValue}
                checked={groupValue === spoolChanged}
                groupValue={groupValue}
                labelHtmlText={labelHtmlText}
                name={groupValue}
                value={spoolChanged}
                onSelected={setSpoolChanged}
              />
            );
          })}
        </Stack>

        <Conditional key="is-new-spool" condition={isNewSpool}>
          <NewSpoolInventory
            isLoading={isLoading}
            isOpaqueMaterial={isOpaqueMaterial}
            materialInventories={materialInventories}
          />
        </Conditional>
      </Stack>
    </Stack>
  );
};
